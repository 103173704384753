<script setup>
  import Image from '@components/Image.vue'

  import { useI18n } from '@shared/i18n.js'

  defineProps({
    property: Object,
  })

  const { t } = useI18n()
</script>

<template>
  <div class="item">
    <a :href="property.path">
      <div class="photo_label">
        <div class="photo">
          <Image :images="property.images" />
        </div>
        <p class="place"><span>{{ property.prefecture }} {{property.property_type_for_type }}</span></p>
      </div>
      <ul class="status">
        <li v-if="property.featured" class="st01">{{ t('c.featured') }}</li>
        <li v-if="property.construction_status" class="st02">{{ property.construction_status }}</li>
        <li v-if="property.purpose" class="st03">{{ property.purpose }}</li>
        <li v-if="property.yield" class="st04">{{ property.yield }}</li>
      </ul>
      <div class="info">
        <p class="price">{{ property.price }}</p>
        <div class="detail">
          <p v-if="property.property_type != 'land'" class="icons_info bed" v-html="property.beds"></p>
          <p v-if="property.property_type != 'land'" class="icons_info bath" v-html="property.bathrooms"></p>
          <p class="icons_info size" v-html="`${property.size}${property.size_sqft}`"></p>
        </div>
        <h4>{{ property.address }}</h4>
      </div>
    </a>
  </div>
</template>

<style scoped>
a {
  padding-bottom: 8px;
}
</style>
